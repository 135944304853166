export default {
    methods: {
        toSendStatusBarcode(barcode, statusId) {
            this.$swal({
                text: 'Statüsünü değiştirmek istediğinizden emin misiniz?',
                title:  barcode.barcode,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet',
                cancelButtonText: 'Hayır',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('product/updateProductBarcode', {id: barcode.id, status_id: statusId})
                        .then(res => {
                            if (res.status == 'success') {
                                barcode.status_id = statusId
                            }
                            this.$swal({
                                title: 'İşlem Tamam',
                                text: res.message,
                                icon: res.status,
                                allowOutsideClick: true,
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            })
                        })
                }
            });
        }
    }
}