<template>
  <div>
    Listesi
    <b-list-group>
      <b-list-group-item
          class="p-0"
          v-for="barcode in barcodeList"
          :key="barcode.id"
          :variant="[1,2].includes(barcode.status_id)?'':'danger'"
      >
        <b-overlay :show="getSupplierStockShow" rounded="sm">
          <feather-icon icon="CopyIcon" @click="doCopy(barcode.barcode)"/> &nbsp;
          {{ barcode.barcode }}
          <b-badge variant="success" @click="getSupplierStock(barcode)">
            <feather-icon icon="LinkIcon"/>
          </b-badge>
          <b-badge
              variant="danger" v-if="[0,5].includes(barcode.status_id)"
              @click="toSendStatusBarcode(barcode,2)"
          >
            <feather-icon icon="ThumbsUpIcon"/>
          </b-badge>

        </b-overlay>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import copyMixins from '@/mixins/copyMixins'
import axios from "@/libs/axios";
import toSendStatusBarcodeMixins from "@/mixins/toSendStatusBarcodeMixins";

export default {
  name: "BarcodeList",
  mixins: [copyMixins, toSendStatusBarcodeMixins],
  data: () => ({
    getSupplierStockShow: false,
  }),
  props: {
    barcodeList: {type: Array, required: true}
  },
  methods: {
    getSupplierStock(barcode) {
      this.getSupplierStockShow = true;
      axios.get('https://api.kitapfirsatlari.com/api/products/supplier-product/' + barcode.barcode)
          .then(response => {
            this.$emit('setSecondProduct', response.data);
            this.secondProduct = response.data;
          }).finally(() => {
        this.getSupplierStockShow = false
      })
    },
  }
}
</script>