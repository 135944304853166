<template>
  <b-modal
      id="edit-product-barcodes-modal"
      ref="edit-product-barcodes-modal"
      cancel-variant="outline-secondary"
      cancel-title="Kapat"
      title="Barkod Düzenleme"
      size="xl"
      @show="showModal"
  >
    <b-overlay :show="show" rounded="sm">
      <table class="table table-hover">
        <thead>
        <tr>
          <th scope="col">Barkod</th>
          <th scope="col">Ekleyen</th>
          <th scope="col">Tarih</th>
          <th scope="col">Yönet</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="barcode in barcodes" :key="barcode.id">
          <td>{{ barcode.barcode }}</td>
          <td>{{ user_name(barcode.user_id) }}</td>

          <td>
            <viewDate name="Ekl.: " :date="barcode.created_at"/>
            <viewDate name="Gnc.: " :date="barcode.updated_at"/>
          </td>
          <td>
            <div>
              <b-badge class="mt-1" :variant="matching_status_color(barcode.status_id)">
                {{ matching_status(barcode.status_id) }}
              </b-badge>
            </div>

            <b-button-group class="mt-1">
              <b-button @click="toSendStatusBarcode(barcode,2)" size="sm" variant="success"> Onayla</b-button>
              <b-button @click="toSendStatusBarcode(barcode,5)" size="sm" variant="warning"> Ertele</b-button>
              <b-button @click="toSendStatusBarcode(barcode,4)" size="sm" variant="danger"> Reddet</b-button>
            </b-button-group>
          </td>
        </tr>
        </tbody>

      </table>
    </b-overlay>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import viewDate from "@/views/common/ViewDate";
import userMixins from "@/mixins/userMixins";
import matchingStatusMixins from "@/mixins/matchingStatusMixins";
import toSendStatusBarcodeMixins from "@/mixins/toSendStatusBarcodeMixins";

export default {
  name: "EditProductBarcodes",
  mixins: [userMixins, matchingStatusMixins, toSendStatusBarcodeMixins],
  components: {viewDate},
  data: () => ({
    show: false,
    barcodes: []
  }),
  computed: {
    ...mapGetters("product", ["_editProduct"])
  },
  methods: {
    showModal() {
      this.barcodes = [];
      this.$store.dispatch('product/showProductBarcodes', this._editProduct.id)
          .then(res => {
            this.barcodes = res.data
          })
    }
  }
}
</script>

<style scoped>

</style>
